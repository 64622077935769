<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.167 9.86906C19.167 8.05607 18.6294 6.28379 17.6221 4.77634C16.6149 3.26889 15.1832 2.09397 13.5083 1.40017C11.8333 0.706364 9.99016 0.524833 8.212 0.878531C6.43384 1.23223 4.8005 2.10527 3.51852 3.38725C2.23653 4.66923 1.36349 6.30258 1.0098 8.08074C0.656097 9.85889 0.837629 11.702 1.53143 13.377C2.22523 15.052 3.40015 16.4836 4.9076 17.4909C6.41505 18.4981 8.18733 19.0357 10.0003 19.0357C11.2041 19.0357 12.3961 18.7986 13.5083 18.338C14.6204 17.8773 15.6309 17.2021 16.4821 16.3509C17.3333 15.4997 18.0086 14.4891 18.4692 13.377C18.9299 12.2648 19.167 11.0728 19.167 9.86906ZM2.667 9.86906C2.667 8.41867 3.09709 7.00084 3.90288 5.79488C4.70868 4.58892 5.85399 3.64899 7.19398 3.09395C8.53397 2.5389 10.0085 2.39368 11.431 2.67664C12.8535 2.9596 14.1602 3.65803 15.1858 4.68361C16.2114 5.7092 16.9098 7.01587 17.1928 8.4384C17.4757 9.86093 17.3305 11.3354 16.7754 12.6754C16.2204 14.0154 15.2805 15.1607 14.0745 15.9665C12.8685 16.7723 11.4507 17.2024 10.0003 17.2024C8.05541 17.2024 6.19014 16.4298 4.81488 15.0545C3.43961 13.6792 2.667 11.814 2.667 9.86906ZM10.917 13.5357L10.917 6.2024C10.917 5.95928 10.8204 5.72612 10.6485 5.55422C10.4766 5.38231 10.2434 5.28573 10.0003 5.28573C9.75721 5.28573 9.52405 5.38231 9.35215 5.55422C9.18024 5.72612 9.08366 5.95928 9.08366 6.2024L9.08366 13.5357C9.08366 13.7788 9.18024 14.012 9.35215 14.1839C9.52405 14.3558 9.75721 14.4524 10.0003 14.4524C10.2434 14.4524 10.4766 14.3558 10.6485 14.1839C10.8204 14.012 10.917 13.7788 10.917 13.5357Z" :fill="fill"/>
    </svg>
</template>

<script>
import { onMounted, ref, computed } from "vue";
// import A from "./A";

export default {
    props: ["fill"],
    // components: {
    //     A,
    // },
    setup(props, { emit }) {
        // const bool = ref(false);
        const fill = computed(() => {
            if (props.fill) {
                return props.fill
            }

            return "#BDBDBD"
        })

        // const a = (item) => {
        //    console.log(item);
        // };


        return {
            // bool,
            fill,
        }
    },
};
</script>


<style lang="scss" scoped>
// .content {
//     display: flex;
//     align-items: center;
//     width: 100%;
// }


</style>