import appointmentService from "@/modules/core/services/appointmentService"
import appointmentPb from "@/modules/core/grpc/generated/appointment_pb";
import router from "../../../router";

const validateNoAuthorization = (error: any) => {
    if(error?.message === 'Acesso não autorizado' || error?.code === 16){
        alert("não autorizado.");
        localStorage.removeItem("token");
        router.push("/account/login");
    }
}


const PausePatientCare = async () => {
    try {
        const req = new appointmentPb.PausePatientCareRequest();
        const res = await appointmentService.pausePatientCare(req);
        const { success } = res

        if (success) {
            return res;
        }

        return { success: false };
    } catch (error) {
        console.log(error);
        return error;
    }
};

const DeleteToken = async () => {
    try {
        const req = new appointmentPb.EmptyRequest();
        const res = await appointmentService.deleteToken(req);
        const { success } = res

        if (success) {
            return res;
        }

        return { success: false };
    } catch (error) {
        console.log(error);
        return error;
    }
};

const ListAppointments = async () => {
    try {
        const req = new appointmentPb.EmptyRequest();
        const res = await appointmentService.listAppointments(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const SearchAppointments = async (word: string) => {
    try {
        const req = new appointmentPb.SearchListPatientsRequest();
        req.setWord(word)
        const res = await appointmentService.searchAppointments(req);

        if (res.success) {
            
            return res;
        }

    } catch (error) {
        console.log(error);
        return error;
    }
};

const ListMedicalCare = async (obj) => {
    try {
        const req = new appointmentPb.ListMedicalCareRequest();
        // console.log("obj", obj.value)
        req.setType(obj.value.type)
        req.setStatus(obj.value.status)
        req.setInitialDate(obj.value.initial_date)
        req.setFinalDate(obj.value.final_date)
        req.addWord(obj.value.word)

        const res = await appointmentService.listMedicalCare(req);

        // console.log("res", res)
        return res;
        
    } catch (error) {
        console.log(error);
        return error;
    }
};

const ConferCheckIn = async (appointment_id: any) => {
    try {
        const req = new appointmentPb.ConferCheckInRequest();
        req.setAppointmentId(appointment_id);
        req.setCodeQrcode(""); // default value

        const res = await appointmentService.conferCheckIn(req);

        console.log("res", res)
        return res;        
    } catch (error) {
        console.log(error);
        return error;
    }
};

const ProfessionalCheckIn = async (obj: any) => {
    try {
        const req = new appointmentPb.ProfessionalCheckInRequest();
        req.setPatientId(obj.patientid);
        req.setCodeQrcode(obj.qrcode); // default value

        const res = await appointmentService.professionalCheckIn(req);

        console.log("res", res)
        return res;        
    } catch (error) {
        console.log(error);
        return error;
    }
};

const UpdateOrderQueue = async (listQueue: any) => {
    try {
        const req = new appointmentPb.UpdateOrderQueueRequest();

        listQueue?.forEach((item: any) => {
            const queueService = new appointmentPb.Queue();
            queueService.setAppointmentid(item.appointmentId);
            queueService.setPosition(item.position);

            req.addQueue(queueService);
        })

        const res = await appointmentService.updateOrderQueue(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const ListWaitingList = async () => {
    try {
        const req = new appointmentPb.EmptyRequest();
        const res = await appointmentService.listWaitingList(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const CancelAppointmentProfessional = async (obj: any) => {
    try {
        const req = new appointmentPb.CancelAppointmentProfessionalRequest();
        req.setAppointmentId(obj.appointmentId);
        req.setDescription(obj.description);

        const res = await appointmentService.cancelAppointmentProfessional(req);

        return res;
    } catch (error) {
        console.log(error);
        return error;
    }
};

const RescheduleAppointmentProfessional = async (obj: any) => {
    try {
        const req = new appointmentPb.RescheduleAppointmentProfessionalRequest();
        req.setAppointmentId(obj.appointmentId);
        req.setInitialDate(obj.initialDate);

        const res = await appointmentService.rescheduleAppointmentProfessional(req);

        return res;
    } catch (error) {
        validateNoAuthorization(error);
        console.log(error);
        return error;
    }
};

export  {
    DeleteToken,
    ListAppointments,
    SearchAppointments,
    ListMedicalCare,
    ConferCheckIn,
    ProfessionalCheckIn,
    UpdateOrderQueue,
    ListWaitingList,
    CancelAppointmentProfessional,
    RescheduleAppointmentProfessional,
    PausePatientCare,
}