<template>
    <div class="bg-modal" v-if="visible">
      <Dialog class="modal" :style="{
        width: '462px',
        borderRadius: '8px',
        zIndex: '999 !important',
      }" :closable="false" :baseZIndex="0" :autoZIndex="false" :visible="visible" @hide="close">
  
        <template #header>
          <h5 class="m-auto">Incluir atendimento</h5>
        </template>

        <div class="content">
            <div class="content-title" v-if="!showOtherFields">
                Faça a busca do paciente pelo CPF ou E-mail 
                <div 
                    class="content-icon"
                    v-tooltip="'Dados deverão ser informados manualmente caso o paciente não possua cadastro.'" type="text" placeholder="Right"
                >
                    <ExclamationMark />
                </div>
            </div>

  
            <span class="cpf  p-input-icon-right">
                <InputMask 
                    ref="cpfRef"
                    id="cpf" 
                    v-model="searchInputCpf" 
                    @change="onChangeSearch($event.target.value)"
                    mask="999.999.999-99" 
                    placeholder="CPF"
                    v-tooltip.focus="'Insira o CPF'" 
                    :class="{ 'p-invalid':  msgErros['patient.Cpf']}"
                    :disabled="loadingCPF || loadingEmail"
                />
                <p v-if="msgErros['patient.Cpf'] && msgErros['patient.Cpf'].length > 0" class="p-invalid">{{ msgErros['patient.Cpf'][0] }}</p>
                <i class="pi pi-spin pi-spinner" v-if="loadingCPF"/>
            </span>

            <span class="email p-input-icon-right">
                <InputText 
                    id="email"  
                    v-model="searchInputEmail"
                    :maxlength="60"
                    @change="onChangeSearchEmail($event.target.value)"
                    placeholder="E-mail" 
                    v-tooltip.focus="'Insira o E-mail'" 
                    :class="{ 'p-invalid':  msgErros['patient.Email'] }"
                    :disabled="loadingCPF || loadingEmail"
                />
                <p v-if="msgErros['patient.Email'] && msgErros['patient.Email'].length > 0" class="p-invalid">{{ msgErros['patient.Email'][0] }}</p>
                <i class="pi pi-spin pi-spinner" v-if="loadingEmail"/>
            </span>

            <span class="nome" v-if="showOtherFields">
                <InputText 
                    id="nome"  
                    v-model="name"
                    placeholder="Nome"
                    :maxlength="20"
                    :value="patient ? patient.firstname : name"
                    :disabled="!patientNotFound"
                    v-tooltip.focus="'Insira o nome'" 
                />
            </span>

            <span class="sobrenome" v-if="showOtherFields">
                <InputText 
                    id="sobrenome"  
                    v-model="lastname"
                    placeholder="Sobrenome" 
                    :maxlength="20"
                    :value="patient ? patient.lastname : lastname"
                    :disabled="!patientNotFound"
                    v-tooltip.focus="'Insira o sobrenome'" 
                />
            </span>

            <span class="ddi" v-if="showOtherFields" :class="!patientNotFound && 'cursor-not-allowed'">
                <Dropdown 
                    v-model="ddi"
                    :options="ddis"
                    optionLabel="ddi" 
                    placeholder="Brasil +55" 
                    :disabled="!patientNotFound"
                />
            </span>

            <span class="phone" v-if="showOtherFields">
                <InputMask 
                    id="phone" 
                    v-model="phone" 
                    mask="(99) 9 9999-9999" 
                    placeholder="Celular" 
                    :disabled="!patientNotFound"
                    v-tooltip.focus="'Insira o celular'" 
                    :class="{ 'p-invalid':  msgErros['patient.Telefone'] }" 
                    @change="clearErrosPhone"
                />
                <p v-if="msgErros['patient.Telefone'] && msgErros['patient.Telefone'].length > 0" class="p-invalid">{{ msgErros['patient.Telefone'][0] }}</p>
            </span>

            <span class="local" v-if="showOtherFields" :class="!phone && 'cursor-not-allowed'">
                <Dropdown 
                    v-model="selectedLocal" 
                    :options="locals" 
                    optionLabel="name" 
                    placeholder="Local de atendimento"
                    :disabled="!phone"
                />
            </span>

            <span class="service" v-if="showOtherFields" :class="!selectedLocal && 'cursor-not-allowed'">
                <Dropdown 
                    v-model="selectedService" 
                    :options="services" 
                    optionLabel="name" 
                    placeholder="Serviço" 
                    :disabled="!services.length || loadingService"
                    :loading="loadingService"
                />
            </span>

            <span class="modality" v-if="showOtherFields" :class="!selectedService && 'cursor-not-allowed'">
                <Dropdown 
                    v-model="selectedModality" 
                    :options="modalitys" 
                    optionLabel="servicemode" 
                    placeholder="Modalidade do atendimento" 
                    :disabled="!modalitys.length || loadingModality"
                    :loading="loadingModality"
                />
            </span>

            <span class="council" v-if="showOtherFields" :class="!selectedModality && 'cursor-not-allowed'">
                <Dropdown 
                    v-model="selectedCouncil" 
                    :options="councils" 
                    optionLabel="council" 
                    placeholder="Profissão" 
                    :disabled="!councils.length || loadingCouncil"
                    :loading="loadingCouncil"
                />
            </span>

            <span class="speciality" v-if="showOtherFields" :class="!selectedCouncil && 'cursor-not-allowed'">
                <Dropdown 
                    v-model="selectedSpecialitie" 
                    :options="specialities" 
                    optionLabel="description" 
                    placeholder="Especialidade" 
                    :disabled="!specialities.length || loadingSpecialitie"
                    :loading="loadingSpecialitie"
                />
            </span>

            <span class="actuation" v-if="showOtherFields" :class="!actuations.length && 'cursor-not-allowed'">
                <Dropdown 
                    v-model="selectedActuation" 
                    :options="actuations" 
                    optionLabel="description" 
                    placeholder="Área de atuação" 
                    :disabled="!actuations.length || loadingActuation"
                    :loading="loadingActuation"
                    v-tooltip="'teste'"
                />
            </span>
            <div class="date-hour" v-if="showOtherFields">
                <span class="date p-input-icon-right">
                    <Calendar 
                        id="calendar-24h" 
                        v-model="datetime24h" 
                        placeholder="Data e horário"
                        showTime 
                        showButtonBar
                        hourFormat="24" 
                        v-tooltip.focus="'Insira a data e hora'"
                        :disabled="!selectedCouncil"
                        dateFormat="dd/mm/yy"
                        :class="{ 'p-invalid': conflict }"
                    />
                    <i class="pi pi-times"  v-tooltip.bottom="'Conflito de horário'" style="color: #F44336; font-size: 8px" v-if="conflict" />
                </span> 
                <span class="duration p-input-icon-right">
                    <InputNumber 
                        v-model="duration" 
                        inputId="minmax" 
                        placeholder="Duração"
                        suffix=" minutos"
                        :min="5" 
                        :max="999" 
                        v-tooltip.focus="'Insira a duração do atendimento'"
                        :disabled="!datetime24h"
                        :class="{ 'p-invalid': conflict }"
                    />
                    <i class="pi pi-times" v-tooltip.bottom="'Conflito de horário'" style="color: #F44336; font-size: 8px" v-if="conflict" />
                </span> 
            </div>

            <span class="big-text p-float-label" v-if="showOtherFields" :class="!selectedCouncil && 'cursor-not-allowed'">
                <Textarea
                v-model="complaint" 
                rows="4"
                cols="30"
                autoResize
                placeholder="Queixa principal" 
                :maxlength="120"
                :disabled="!selectedCouncil"
                v-tooltip.focus="'Informe o motivo do atendimento'" 
                />
            </span>
            
  
        </div>
  
        <template #footer>
          <div class="buttons">
            
            <Button class="cancel" label="Cancelar" @click="close"/>
            <Button class="submit"
             :disabled="!complaint || !datetime24h || !duration ||loadingSubmit"  
             @click="addAppointmentProfessional(patient, selectedLocal, formattedTimeTest, selectedService, selectedModality, selectedCouncil, selectedSpecialitie, selectedActuation, ddi, name, lastname, searchInputCpf, searchInputEmail, complaint, phone)"
             >
              Confirmar  <i class="pi pi-spin pi-spinner" v-if="loadingSubmit" />
            </Button>
  
          </div>
        </template>
  
      </Dialog>
    </div>
</template>
  
<script setup>
import { ref, onMounted, watch, computed, onUnmounted, nextTick } from "vue"
import debounce from 'lodash.debounce'
import {
SearchCpfRequest,
SearchEmailRequest,
VerifyConflictRequest,
AddAppointmentProfessionalRequest,
} from "../../../core/grpc/generated/appointment_grpc_web_pb";
import appointmentService from "../../../core/services/appointmentService";
import professionalProfileService from "../../../core/services/professionalProfileService";
import {
//   ListPlacesRequest,
  ListServicesPlaceRequest
} from "../../../core/grpc/generated/professionalProfile_grpc_web_pb";
import { ListPlaces, ListServiceIdPlace } from "@/modules/core/actions/professionalProfile"

// Icons
// import Alert from "@/assets/IconsComponent/Alert.vue"
import ExclamationMark from "@/assets/IconsComponent/ExclamationMark.vue"

const props = defineProps(["visible"]);
const emit = defineEmits(["update:visible", "error", "success"]);

const cpfRef = ref();

const loadingSubmit = ref(false);
const searchInputCpf = ref("");
const searchInputEmail = ref("");
const patient = ref(null);
const patientNotFound = ref(false);
const ddi = ref("");
const locals = ref([]);
const selectedLocal = ref();
const selectedService = ref();
const selectedModality = ref();
const selectedSpecialitie = ref();
const selectedCouncil = ref();
const selectedActuation = ref();
const services = ref([]);
const modalitys = ref([]);
const specialities = ref([]);
const councils = ref([]);
const actuations = ref([]);
const datetime24h = ref(null);
const time = ref();
const duration = ref(null);
const ddis = ref([{
    ddi: "Brasil +55"
}]);
const day = ref();
const month = ref();
const year = ref();
const hour = ref();
const minutes = ref();
const loadingService = ref(false);
const loadingModality = ref(false);
const loadingCouncil = ref(false);
const loadingSpecialitie = ref(false);
const loadingActuation = ref(false);
const serviceId = ref();
const name = ref();
const lastname = ref();
const phone = ref();
const complaint = ref();
const loadingCPF = ref(false);
const loadingEmail = ref(false);
const conflict = ref(false);
const msgErros = ref({});


const close = () => {
    searchInputCpf.value = "";
    searchInputEmail.value = "";
    patient.value = [];
    selectedLocal.value = ("");
    selectedService.value = ("");
    selectedModality.value = ("");
    selectedCouncil.value = ("");
    selectedSpecialitie.value = ("");
    selectedActuation.value = ("");
    services.value = [];
    modalitys.value = [];
    councils.value = [];
    specialities.value = [];
    actuations.value = [];
    datetime24h.value = ("");
    time.value = ("");
    duration.value = null;
    patientNotFound.value = false;
    loadingSubmit.value = false;
    serviceId.value = ("");
    name.value = "";
    lastname.value = "";
    phone.value = null;
    complaint.value = "";
    conflict.value = false;
    msgErros.value = ref(""); 
    emit("update:visible", false)
};

const removeDDI = (phone) => {
    if (phone) {
        const ddi = "+55"; // Código DDI para o Brasil
        return phone.replace(ddi, "").trim();
    }
    return "";
};

// Search by CPF
const searchCpf = async (word) => {
    loadingCPF.value = true;
    if (word.trim() !== "") {
        try {
            let req = new SearchCpfRequest();
            req.setCpf(word);

            let res = await appointmentService.searchCpf(req);

            if (res?.success) {
                patient.value = res.patient;
                const phoneMask = removeDDI(res.patient.phone);
                phone.value = phoneMask;
                patientNotFound.value = false;

                if (res?.patient && res?.patient.email) {
                searchInputEmail.value = res.patient.email;
                } else {
                searchInputEmail.value = "";
                }
            } else {
                patientNotFound.value = true;
                patient.value = undefined;
            }
        } catch (error) {
        console.log(error);
        }
    }
    loadingCPF.value = false;
};

const onChangeSearch = (value) => {
    clearErrosCpf()
    searchInputCpf.value = value.replace(/\D/g, ""); // Remove não dígitos do valor
    searchCpf(searchInputCpf.value);
};

// Search by E-mail
const searchEmail = async (word) => {
    loadingEmail.value = true;
    if (word.trim() !== "") {
        try {
            let req = new SearchEmailRequest();
            req.setEmail(word);

            let res = await appointmentService.searchEmail(req);
            console.log(res);

            if (res.success) {
                patient.value = res.patient;
                const phoneMask = removeDDI(res.patient.phone)
                phone.value =  phoneMask;;
                patientNotFound.value = false;

                if (res.patient && res.patient.cpf) {
                searchInputCpf.value = res.patient.cpf;
                } else {
                searchInputCpf.value = "";
                }
            } else {
                patientNotFound.value = true;
                patient.value = undefined;
            }
        } catch (error) {
        console.log(error);
        }
    }
    loadingEmail.value = false;
};

const onChangeSearchEmail = (value) => {
    clearErrosEmail()
    searchEmail(value);
};

const listPlacesActives = async (status) => {
    try {
        const res = await ListPlaces(status)

        if (res?.success && res.placesList) {
        locals.value = res.placesList;
        console.log(res.placesList)
        }
    } catch (error) {
        console.log(error);
    }
};


const listServicesPlace = async (selectedLocal) => {
    try {
        const req = new ListServicesPlaceRequest();
        console.log("selectedLocal id:", selectedLocal.id)
        req.setAddressplaceid(selectedLocal.id)

        const res = await professionalProfileService.listServicesPlace(req);
        const { success, servicesList } = res;
        console.log(res)

        if (success && servicesList && servicesList.length) {
        services.value = servicesList;
        }
    } catch (error) {
        console.log(error);
    }
};

const listModalitys = async (selectedService) => {
    if (selectedService && selectedService.servicetypesList) {
        const servicetypesList = selectedService.servicetypesList;
        console.log(servicetypesList);
        modalitys.value = servicetypesList
        } else {
        console.log("selectedService.value ou servicetypesList é indefinido");
        // Lógica de tratamento de erro, se necessário...
    }
};

const listCouncils = async (selectedService, selectedLocal) => {
    try {
        const res = await ListServiceIdPlace(selectedService, selectedLocal);
        if (res?.success && res.servicesList) {

        councils.value = res.servicesList[0].councilList
        console.log(councils.value)
        }
    } catch (error) {
        console.log(error);
    }
};

const verifyConflict = async (obj) => {
    loadingSubmit.value = true
    try {
        conflict.value = false;
        const req = new VerifyConflictRequest();
        req.setDate(obj.date);
        req.setInitialhour(obj.hour);
        req.setEstimatedtime(obj.duration);

        const res = await appointmentService.verifyConflict(req);
        if (res?.success) {
            console.log(res)
        } else {
            conflict.value = true;
            datetime24h.value = null;
            duration.value = null;
            console.log('Conflito', res.listconflictsList);
            const obj = {
                title: "Erro ao incluir atendimento",
                message: res.errormessage,
                listconflictsList: res.listconflictsList,
            }
            emit("error", obj)
        }

        // if (success && servicesList && servicesList.length) {
        // services.value = servicesList;
        // }
    } catch (error) {
        console.log(error);
    }
    loadingSubmit.value = false;
};

const addAppointmentProfessional = async (patient, selectedLocal, formattedTimeTest, selectedService, selectedModality, selectedCouncil, selectedSpecialitie, selectedActuation, ddi, name, lastname, searchInputCpf, searchInputEmail, complaint, phone) => {
loadingSubmit.value = true
    try {
        const req = new AddAppointmentProfessionalRequest();
        console.log(formattedTimeTest)

        const servicePatient = new proto.appointmentApi.Patient()
        servicePatient.setFirstname(patient ? patient.firstname : name);
        servicePatient.setLastname(patient ? patient.lastname : lastname);
        servicePatient.setEmail(patient ? patient.email : searchInputEmail);
        servicePatient.setCountrycode("+55");

        const cleanedNumber = phone.replace(/\D/g, '');
        servicePatient.setPhone(cleanedNumber);
        console.log(cleanedNumber);

        const cpfWithoutPunctuation = searchInputCpf.replace(/\D/g, "");
        servicePatient.setCpf(patient ? patient.cpf : cpfWithoutPunctuation);

        req.setPatient(servicePatient);
            console.log(servicePatient);

        req.setPlaceid(selectedLocal.id);
        req.setDate(formattedTimeTest.date);
        req.setInitialhour(formattedTimeTest.hour);
        req.setDayofyear(10);
        req.setServicename(selectedService.name);
        req.setServicedescription(selectedService?.description);
        req.setTuss(selectedService?.tuss);
        req.setTussterm(selectedService?.tussterm);
        req.setSus(selectedService?.sus);
        req.setSusprocedure(selectedService?.susprocedure);
        req.setServicereturn(selectedService.pb_return);
        req.setServicetimereturn(selectedService.timereturn);
        req.setServicemode(selectedModality.servicemode);
        req.setEstimatedtime(formattedTimeTest.duration);
        req.setValue(selectedModality.value);
        req.setMaxdisplacement(selectedModality?.maxDisplacement);
        req.setValuekm(selectedModality?.valuekm);
        req.setValuepercentage(selectedModality?.valuepercentage);
        req.setOccupation(selectedCouncil.council);
        req.setSpecitliaty(selectedSpecialitie?.description);
        req.setActuation(selectedActuation?.description);
        req.setChiefComplaint(complaint)


        const res = await appointmentService.addAppointmentProfessional(req);
        console.log(res)
        if ( res?.success ) {
            close();
            const objSuccess = {
                title: "Sucesso",
                message: res.errormessage
            }
            emit("success", objSuccess);

            return success;

        } else {
            console.log('Falha ao incluir atendimento', res.errormessage);
            msgErros.value = JSON.parse(res.data);
            console.log(JSON.parse(res.data))

            const obj = {
                title: "Erro ao incluir atendimento",
                message: res.errormessage
            }
            emit("error", obj);
        }
    } catch (error) {

        return null;
        
    } finally {
        loadingSubmit.value = false;
    }
}; 

watch(selectedLocal, (newValue) => {
    if (newValue) {
        listServicesPlace(newValue);
        
        selectedService.value = null;
        selectedModality.value = null;
        selectedCouncil.value = null;
        selectedSpecialitie.value = null;
        specialities.value = [];
        selectedActuation.value = null;
        actuations.value = [];

        loadingService.value = true;
    } else {
        services.value = []; // Limpa a lista de serviços se nenhum local for selecionado
        loadingService.value = false;
    }
});

watch(services, (newValue) => {
    if (newValue && newValue.length) {
        loadingService.value = false;
    }
});

watch(selectedService, (newValue) => {
    if (newValue) {
        listModalitys(newValue);
        serviceId.value = selectedService.value.id
        selectedModality.value = null;
        selectedCouncil.value = null;
        selectedSpecialitie.value = null;
        specialities.value = [];
        selectedActuation.value = null;
        actuations.value = [];

        loadingModality.value = true;
    } else {
        modalitys.value = [];
        loadingModality.value = false;
    }
});

watch(modalitys, (newValue) => {
    if (newValue && newValue.length) {
        loadingModality.value = false;
    }
});

watch(selectedModality, (newValue) => {
    if (newValue) {
        listCouncils(selectedService, selectedLocal)
        selectedCouncil.value = null;
        selectedSpecialitie.value = null;
        specialities.value = [];
        selectedActuation.value = null;
        actuations.value = [];

        loadingCouncil.value = true;
    } else {
        councils.value = [];
        loadingCouncil.value = false;
    }
});

watch(councils, (newValue) => {
    if (newValue && newValue.length) {
        loadingCouncil.value = false;
    }
});

watch(selectedCouncil, (newValue) => {
    if (newValue) {
        specialities.value = selectedCouncil.value.specialityList
        selectedSpecialitie.value = null;
        selectedActuation.value = null;
        actuations.value = [];

        loadingSpecialitie.value = true;
    } else {
        specialities.values = [];
        loadingSpecialitie.value = false;
    }
});

watch(specialities, (newValue) => {
    if (newValue && newValue.length) {
        loadingSpecialitie.value = false;
    }
});

watch(selectedSpecialitie, (newValue) => {
    if (newValue) {
        actuations.value = selectedSpecialitie.value.actuationList
        selectedActuation.value = null;
    }
});

watch(actuations, (newValue) => {
    if (newValue && newValue.length) {
        loadingActuation.value = false;
    }
});

watch([searchInputCpf, searchInputEmail], () => {
    if (!searchInputCpf.value && !searchInputEmail.value) {
        patient.value = [];
        name.value = "";
        lastname.value = "";
        phone.value = "";
        patientNotFound.value = false;
    }
});

const clearErrosCpf = () => {
    if (msgErros.value.hasOwnProperty("patient.Cpf")) {
        delete msgErros.value['patient.Cpf']
    }
};

const clearErrosEmail = () => {
    if (msgErros.value.hasOwnProperty("patient.Email")) {
        delete msgErros.value['patient.Email']
    }
};

const clearErrosPhone = () => {
    if (msgErros.value.hasOwnProperty("patient.Telefone")) {
        delete msgErros.value['patient.Telefone']
    }
};

const showOtherFields = computed(() => {
    if (searchInputEmail.value && searchInputCpf.value) {
        return true
    }
    return false
})
const formattedTimeTest = computed(() => {
    if (datetime24h.value && duration.value) {
        const day = datetime24h.value.getDate();
        const month = datetime24h.value.getMonth() + 1;
        const year = datetime24h.value.getFullYear();
        const hour = datetime24h.value.getHours();
        const minutes = datetime24h.value.getMinutes();

        const date  = `${year}-${month.toString().padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
        const initialHour = `${hour.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

        return {
            date: date,
            hour: initialHour,
            duration: duration.value
        }
    }
    return null
})

watch(formattedTimeTest,  debounce((newValue) => {
    if (newValue) {
        verifyConflict(newValue);
    }
    console.log(newValue)
}, 500));

watch(duration, (newValue) => {
    if (newValue) {
        loadingSubmit.value = true;
    }
});

function closeToESC (event) {
    if(event.key === "Escape") {
        close();
    }
}

onUnmounted(() => {
    window.removeEventListener("keydown", closeToESC);
})

onMounted(async () => {
    listPlacesActives("Ativos");

    // closeToESC
    window.addEventListener("keydown", closeToESC);
    await nextTick();
    cpfRef.value.$el.focus();
});      
</script>
  
  <style lang="scss" scoped>
  .bg-modal {
    width: 120vw;
    height: 120vh;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 997;
    background: rgb(250, 250, 250, .05);
    mask: radial-gradient(circle, rgba(0, 0, 0) 100%);
    -webkit-mask: radial-gradient(circle, rgba(0, 0, 0) 100%);
    backdrop-filter: blur(6px);
  }
  .buttons{
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 8px;
}
  
  .info-container {
    display: flex;
    flex-direction: column;;
    align-items: center;
    gap: 10px;
    background: #FAFAFA;
    padding: 14px;
    border-radius: 20px;
    min-height: 100%;
  
    .tittle {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #828282;
    }
    .link {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 12px;
      line-height: 14px;
      color: #828282;
    }
  }
  
  .content {
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 8px;

    .big-text {
        padding-top: 8px;
    }
    
    .date-hour {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .date {

            .p-calendar{
                width: 100%;
            }
        }
    }
  
    .message-error {
      display: flex;
      align-items: center;
      gap: 12px;
  
      span {
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 31px;
        white-space: nowrap;
        color: #EB5757;
      }
    }
    .p-dialog-header {
      span {
        font-family: 'Roboto' !important;
        font-style: normal !important;
        font-weight: 500 !important;
        font-size: 18px !important;
        line-height: 21px !important;
        color: #828282 !important;
      }
    }
  }

  .content-title {
    display: flex;  
    justify-content: center;
    align-items: center;

    gap: 6px;

    color: var(--gray-2, #4F4F4F);
    font-family: Roboto;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; 

    .content-icon {
        display: flex;
        align-items: center;
    }
  }
  
  :deep() {

    .p-invalid {
        padding-top: 2px;
        padding-left: 20px;
    }

    .p-inputtext {
        height: 40px;
        border-radius: 8px;
        padding: 0 19px;
        border: 1px solid bdbdbd;
        
        outline: none;
    
        // &::-webkit-input-placeholder {
        // color: #bdbdbd;
        // }

        // &:disabled {
        //     height: 40px;
        //     border-radius: 8px;
        //     padding: 0 19px;
        //     color: #828282;
        //     border: 1px solid bdbdbd;
        //     outline: none;
        // }
    
        &:focus {
        border-color: #ff6a33;
        }
    }

    .p-inputnumber {
        width: 100%;
    }

    .p-dropdown {
        height: 40px;
        border-radius: 8px;
        border: 1px solid #F2F2F2;
        outline: none;

        .p-dropdown-label {
            padding-top: 8px;
            // opacity: 0.6;
        }

        .p-dropdown-label .p-disabled {
            color:#bdbdbd;
            opacity: 0.6;
            border: 1px solid #F2F2F2;


        }
    };
    .cancel {
      background: #FAFAFA;
      border-color: #FAFAFA;
      box-shadow: none;
  
      color: #BDBDBD;
      border-radius: 128.571px;
  
      &:hover {
        background: #FAFAFA;
        border-color: #FAFAFA;
        box-shadow: none;
        color: #BDBDBD;
        text-shadow: #BDBDBD 0 0 0.1rem;
      }
    }

    .submit {
        background: #FAFAFA !important;
        border-color: #FAFAFA;
        box-shadow: none;
    
        color: #828282;
        border-radius: 128.571px;
        gap: 8px;
        min-width: 104px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    
        &:disabled {
            background: #F5F5F5 !important;
            border-color: #F5F5F5;
            box-shadow: none;
            color: #BDBDBD !important;
            border-radius: 128.571px;
            gap: 8px;
            min-width: 104px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: not-allowed !important;
        }
    
        &:hover {
            background: #FAFAFA !important;
            border-color: #FAFAFA;
            box-shadow: none;
            color: #828282 !important;
            text-shadow: #828282 0 0 0.1rem; 
        }
    }
  };
  </style>
  